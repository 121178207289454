import './App.scss';
import React, {useEffect, useState} from 'react'
import axios from 'axios'


function App() {

  const [details, setDetails] = useState(null)
  const [backgroundImage, setBackgroundImage] = useState("")
  const [content1, setContent1] = useState(false)
  const [content2, setContent2] = useState(false)

function getPathToAsset(assetName){
  let host = window.location.hostname;
  return `/${host}/${assetName}`;
}

async function processDetails(){
  
  let host = window.location.hostname;
  let path = `/${host}/details.json`;

  const resp = await axios.get(path);
  setDetails(resp.data);
  setBackgroundImage(getPathToAsset(resp.data.backgroundImage));
  return resp.data;
}

async function processContent(content, contentSetter){
    if(content.endsWith('.txt') || content.endsWith('.html')){
      try{
        let path = getPathToAsset(content);
        //if content is a path to a text file
        const resp = await axios.get(path);
        contentSetter(resp.data);
      } catch (err) {
        console.error('content', err);
      }
    }
    else {
      //if content is just text
      contentSetter(content);
    }
}

  var styles = {
    app:{
      backgroundColor: ""
    },
    appHeader:{
      color:""
    },
    appContent:{
      color:""
    },
    contactInfo:{
      backgroundColor:""
    }
  }

  useEffect(() => {
    (async () => {
      try {
        var deets = await processDetails();
        await processContent(deets.content1,setContent1);
        await processContent(deets.content2,setContent2);

        document.title = deets.companyName

      } catch (err) {
        console.error('details', err);
      }
    })()
  }, [])

  return (
      <div className="app" style={{backgroundColor:details?.color1}}>
        <h1 className="app-header" style={{color:details?.headerFontColor}}>
          {details?.companyName}
        </h1>
        <img className="background" src={backgroundImage} />
        <div className="app-content" style={{color:details?.contentFontColor}}>
          <div className="content" dangerouslySetInnerHTML={{__html: content1}}/>
          <div className="content" dangerouslySetInnerHTML={{__html: content2}}/>
        </div>
        <div className="contact-info" style={{backgroundColor:details?.color2}}>
          <div className='phone-number'>Phone Number: {details?.phoneNumber}</div>
          <div className="email-address">Email address: {details?.emailAddress}</div>
        </div>
      <div className="copyright">Ⓒ {details?.companyName} - {(new Date()).getFullYear()}</div>
    </div>
  );
}

export default App;
